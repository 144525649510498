.App {
  text-align: left;
}

.header {
  text-align: center;
}

.countdown {
  font-size: 100px;
  font-weight: bolder;
  color:red;
}

.personIcon {
  width: 7px;
  margin: 5px;
}

.dataSource {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px;
}

.moreInfo {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 15px;
}

.git {
  width: 100px;
  align-self: center;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid red; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-top: 50px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.notJustAFlu { 
  font-size:50px;
}

.video-container {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width:50%;
}

@media only screen and (max-width: 600px) {
  .video-container {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width:90%;
  }
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}